import { useCallback, useContext } from 'react';

import { HistoryContext } from '#/context/AppHistoryContext';

const useGetHistoryStack = () => {
  const historyContext = useContext(HistoryContext);
  if (historyContext === undefined) {
    throw new Error('useGetHistoryStack must be used within a HistoryProvider');
  }
  return useCallback(() => historyContext.stack, [historyContext]);
};

export default useGetHistoryStack;
