import React, { useState, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { AuthContextProvider } from '#/context/AuthContext';
import { HistoryProvider } from '#/context/AppHistoryContext';
import { ConfigProvider } from '#/context/ConfigContext';
import { I18nProvider } from '#/context/i18nContext';
import APIErrorProvider from '#/context/ApiErrorContext';
import { ExtraStyleClassesProvider } from '#/context/ExtraStyleClassesProvider';
import checkLegacyDevice from '#/utils/checkLegacyDevice';
import App from '#/components/App/App';

import logger from '#/utils/logger';

// And the preflight loader NEEDS to happen BEFORE the app itself
// loading the XDK4 Core module, which is going to happen in
// Viki's AppShell.

// from client.js
// import addWhyDidYouRender from '#/client/addWhyDidYouRender';

import AppShell from './AppShell';

// addWhyDidYouRender(); uncomment this to enable whyDidYouRender

let xdkInit = false;

const AppWrapper = () => {
  const [xdkPlatform, setXdkPlatform] = useState(null);

  const importAndLoadXdk = async () => {
    // xdk-core export both using default export and named export,
    // and xdk.load() in the XDK4 Getting Started guide
    // is using the default export of xdk-core.
    const { default: XDK } = await import('@accedo/xdk-core');

    return XDK.load();
  };

  if (!xdkInit) {
    importAndLoadXdk().then(xdk => {
      const platform = xdk.getId();

      logger.info(`XDK device onload, platform: ${platform}`);

      if (__LOCAL__) {
        window.xdk = xdk;
      }
      if (!('scrollBehavior' in document.documentElement.style)) {
        import('smoothscroll-polyfill').then(smoothscroll => {
          smoothscroll.polyfill();
        });
      }
      setXdkPlatform(platform);

      checkLegacyDevice();
    });

    xdkInit = true;
  }

  useEffect(() => {
    // To avoid the white screen between the splash deletion and the React load time
    const appBody = document.getElementsByTagName('body')[0];
    appBody.style.backgroundColor = 'rgb(32, 32, 32)';
  }, []);

  if (!xdkPlatform) {
    return null;
  }

  return (
    <AuthContextProvider>
      <APIErrorProvider>
        <ExtraStyleClassesProvider>
          <I18nProvider>
            <ConfigProvider>
              <HistoryProvider>
                <AppShell platform={xdkPlatform}>
                  <Router>
                    <App platform={xdkPlatform} />
                  </Router>
                </AppShell>
              </HistoryProvider>
            </ConfigProvider>
          </I18nProvider>
        </ExtraStyleClassesProvider>
      </APIErrorProvider>
    </AuthContextProvider>
  );
};

export default AppWrapper;
