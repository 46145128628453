/**
 * @module services/analytics/gtag
 * @description
 * Helper to send events to google tag manager/gtag. This helper expects a well formed object
 * to be set directly on the dataLayer from the library.
 * @param {object} opts Object with the expected google tag manager variables
 * @property {sting} opts.name Event name to send
 * @property {sting} opts.params params to send to Google Analaytics as Event params
 * @return {boolean} true if the push is correct
 * @throws {error} Exception if the dataLayer is not able to push the variables object
 */
const sendGoogleTagManagerEvent = ({ name, params }) => {
  window.gtag('event', name, params);
};
export default sendGoogleTagManagerEvent;
