/**
 * @module models/CMS
 */
/**
 * This model represents the Menu.
 *
 * vikiElevateContainer representation
 * @typedef {Object} vikiElevateContainer
 * @property {String} title container's title
 * @property {String} id identifier of the current container
 * @property {String} displaytext container text property
 * @property {String} items container items
 * @property {String} query container's query to retrieve items
 * @property {String} template container template
 * @property {String} subtitle container's subtitle
 */

/**
 * vikiElevateContainer Model
 * @param {Object} rawContainer Container
 * @returns {vikiElevateContainer} Container - The {@link vikiElevateContainer} representation
 */
const container = rawContainer => {
  const {
    displayText,
    items,
    id,
    query,
    template,
    title,
    subtitle
  } = rawContainer;

  const getTitle = () => {
    return title;
  };

  const getDisplayText = () => {
    return displayText;
  };

  const getItems = () => {
    return items;
  };

  const getId = () => {
    return id; // to add it into the docs
  };

  const getQuery = () => {
    return query;
  };

  const getTemplate = () => {
    return template;
  };

  const getSubtitle = () => {
    return subtitle;
  };

  return {
    displayText: getDisplayText(),
    id: getId(),
    items: getItems(),
    query: getQuery(),
    template: getTemplate(),
    title: getTitle(),
    subtitle: getSubtitle()
  };
};

export default container;
