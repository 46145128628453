import controlConfig from './config';
import * as controlUtils from './utils';

let extendedConfig = { ...controlConfig };

let accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);

export const accedoOneClientInstanceBuilder = userId => {
  if (userId) {
    const calculatedGid = userId;
    if (calculatedGid !== extendedConfig.gid) {
      extendedConfig = {
        ...extendedConfig,
        gid: calculatedGid
      };
      accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);
    }
    return accedoOneClientInstance;
  }
  // If was previously logged-in but now it's not, we need to recreate
  if (extendedConfig.gid !== 'amagi') {
    extendedConfig.gid = 'amagi';
    accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);
  }
  return accedoOneClientInstance;
};
