import cookie from 'react-cookie';

import * as vikimap from '@accedo/vdkweb-vikimap';

import config from '#/config';

/**
 * @module providers/cms/control
 * @description
 * Provider CMS implementation from Accedo Control
 */

import controlConfig, {
  APPLICATION_KEY,
  DEVICE_ID,
  SESSION_KEY,
  SIXTY_YEARS_IN_MS
} from '#/providers/shared/control/config';

import { accedoOneClientInstanceBuilder } from '#/providers/shared/control/control';

const appConfig = config.app;
let accedoControlService = null;
let accedoOneClientInstance = null;
let configuration = null;
let menu = null;
let menuLocaleCode = null;

const CONFIG_KEYS_TOCHECK = ['defaultLocale', 'dictionary', 'mainMenuEntryId'];

const checkEmptyValue = (configKey, metadata) => {
  if (metadata[configKey] === undefined || metadata[configKey] === '') {
    if (appConfig[configKey] === undefined || appConfig[configKey] === '') {
      console.error(
        `[debug] Config Key ${configKey} is not set up, please review your local and remote config to add it`
      );
      return;
    }
    metadata[configKey] = appConfig[configKey];
  }
};

const checkEmptyValues = metadata => {
  CONFIG_KEYS_TOCHECK.forEach(configKey => {
    checkEmptyValue(configKey, metadata);
  });

  return metadata;
};

const setAccedoControlService = userId => {
  accedoOneClientInstance = accedoOneClientInstanceBuilder(userId);
  accedoControlService = vikimap.getAccedoOneService(accedoOneClientInstance);
};

/**
 *
 * Fetch the Configuration CMS content
 * @param {strign} userId the userId
 * @returns {Configuration} The Configuration
 */
const getConfiguration = async userId => {
  setAccedoControlService(userId);
  const metadata = await accedoOneClientInstance.getAllMetadata();
  const assets = await accedoOneClientInstance.getAllAssets();
  const status = await accedoOneClientInstance.getApplicationStatus();
  checkEmptyValues(metadata, appConfig); // It will use local config if available when not on remote
  configuration = {
    ...appConfig,
    ...status,
    ...metadata,
    userId,
    assets
  };
  return configuration;
};

/**
 *
 * Fetch the Menu CMS content
 *
 * @param {String} id the Menu id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Menu} The Menu
 */
const getMenu = async ({ id, options, auth }) => {
  setAccedoControlService(auth);
  if (menu && options.locale === menuLocaleCode) {
    return menu;
  }
  menu = await accedoControlService.getMenu(id, options);
  menuLocaleCode = options?.locale || configuration?.defaultLocale;
  return menu;
};

const getRoutesMapping = async auth => {
  setAccedoControlService(auth);
  const {
    routesMapping: routesMappingId,
    mainMenuEntryId
  } = await getConfiguration();
  let routesMapping;
  if (routesMappingId) {
    routesMapping = await accedoControlService.getItem(routesMappingId);
    routesMapping.mappings = await accedoControlService.getItemsByIds(
      routesMapping.mappings
    );
    const pageIds = routesMapping.mappings.map(map => map.page);
    const pageResponses = await accedoControlService.getItemsByIds(pageIds);
    routesMapping.mappings.forEach(mapItem => {
      const pageResponse = pageResponses.find(
        pageResponseItem => pageResponseItem.id === mapItem.page
      );
      mapItem.page = pageResponse;
    });
  }
  if (mainMenuEntryId) {
    const menuResponse = await getMenu({ id: mainMenuEntryId });
    menuResponse.items.forEach(menuItem => {
      routesMapping.mappings.push(menuItem);
    });
  }
  return routesMapping?.mappings || [];
};

/**
 *
 * Fetch the Page CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The page
 */
const getPageLayout = async ({ id, options, auth }) => {
  setAccedoControlService(auth);
  return accedoControlService.getPage(id, options);
};

const getDefaultTheme = async auth => {
  setAccedoControlService(auth);
  const { defaultTheme } = await getConfiguration();
  let theme;
  if (defaultTheme) {
    theme = await accedoControlService.getItem(defaultTheme);
  }
  return theme;
};

/**
 *
 * Fetch the Page Theme CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The theme
 */
const getThemeByPage = async ({ id, options, auth }) => {
  setAccedoControlService(auth);
  return accedoOneClientInstance.getEntryById(id, options).then(page => {
    if (!page.theme) {
      return;
    }
    return accedoOneClientInstance.getEntryById(page.theme, options);
  });
};

export const cleanAccedoOneSessionInClient = () => {
  const appKey = cookie.load(APPLICATION_KEY);

  if (appKey !== controlConfig.appKey && controlConfig.appKey) {
    cookie.remove(APPLICATION_KEY);
    cookie.remove(DEVICE_ID);
    cookie.remove(SESSION_KEY);

    cookie.save(APPLICATION_KEY, controlConfig.appKey, {
      path: '/',
      maxAge: SIXTY_YEARS_IN_MS
    });
  }
};

export default () => {
  return {
    getMenu,
    getRoutesMapping,
    getConfiguration,
    getPageLayout,
    getDefaultTheme,
    getThemeByPage
  };
};
