// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import fetch from '@accedo/vdkweb-fetch';
import { XMLParser } from 'fast-xml-parser';

import { getConfiguration } from '#/services/cms';
import fetcher from '#/services/helpers/fetcher';
import { accedoOneClientInstanceBuilder } from '#/providers/shared/control/control';
import LiveProgramBuilder from '#/models/live/liveProgram';
import { parseXMLTVDateToDate } from '#/utils/xmltv';
import { CONTAINER_ITEM_TYPES } from '#/config/constants';
import config from '#/config';
import {
  // getProtocolRelativePathUrl,
  getQueryStringParameters
} from '#/utils/url';

import channelDefEntryMock from './data/channelDefEntry.json';
import channelEntriesMock from './data/channelEntries.json';

const parser = new XMLParser({ ignoreAttributes: false });

const {
  amagi: {
    middlewareUrl,
    listingsEndpoint,
    channelListingEndpoint,
    amagiOvpUrl
  }
} = config.app;

const channelProgramData = {};

const TIME_PADDING = 1000 * 60 * 2; // 2 Minutes

const errorHandler = error => console.error('[OVP-AMAGI]', error);

const getChannelData = async (userId, filterHighlight = false) => {
  let channelsConfigField;
  let channelDefEntryResponse = {};
  let channelEntriesResponse = {};
  const accedoOneClientInstance = accedoOneClientInstanceBuilder(userId);
  try {
    const cmsConfiguration = await getConfiguration(userId);
    channelsConfigField = cmsConfiguration.integrations?.amagi?.channels;
  } catch (e) {
    console.warn(
      `Unable to get configuration from Control, returning default. Error: ${e}`
    );
  }
  try {
    const channelsEntrycacheId = `amagi-${userId ? `-${userId}` : ''}-channels`;
    channelDefEntryResponse = await fetcher({
      cacheId: channelsEntrycacheId,
      fetchFn: () =>
        accedoOneClientInstance.getEntryById(channelsConfigField, {})
    });
  } catch (e) {
    console.warn(
      `Unable to get data from Channels Entries from Control, returning default. Error: ${e}`
    );
    channelDefEntryResponse = channelDefEntryMock;
  }
  const channelIds = channelDefEntryResponse.channels;
  try {
    const channelsCacheId = `amagi-${
      userId ? `-${userId}` : ''
    }-channels-${channelIds}`;
    channelEntriesResponse = await fetcher({
      cacheId: channelsCacheId,
      fetchFn: () =>
        accedoOneClientInstance.getEntries({
          id: channelIds
        })
    });
  } catch (e) {
    console.warn(
      `Unable to get data from Channels from Control, returning default. Error: ${e}`
    );
    channelEntriesResponse = channelEntriesMock;
  }

  // Add order based on Control responde to ensure same order when receive it from the API response
  channelEntriesResponse.entries = channelEntriesResponse.entries.map(
    (channelEntry, index) => ({
      ...channelEntry,
      // [AMAGI][PALIATIVE SOLUTION]
      //
      // Both videoURL and type are required by ChannelBanner.
      // We have a default rule on "Shelf" component that checks and uses these
      // parameters to redirect the user to the player on the click action. It's a paliative solution
      // that solves this issue for the AMAGI DEMO scheduled for 15/03/2023.
      videoUrl: channelEntry.streamUrl,
      type: CONTAINER_ITEM_TYPES.Live,
      order: index
    })
  );
  const returningChannelEntriesResponse = structuredClone(
    channelEntriesResponse
  );
  if (filterHighlight) {
    // Filter by highlight if needed (for Channelbanner Rail)
    returningChannelEntriesResponse.entries = returningChannelEntriesResponse.entries.filter(
      channelEntry => filterHighlight === channelEntry.highlight
    );
  }

  return Promise.resolve(returningChannelEntriesResponse);
};

const getChannelProgramData = async channel => {
  if (!channelProgramData[channel.channelId]) {
    channelProgramData[channel.channelId] = fetch(channel.feedUrl, {});
  }
  const feedResponse = await channelProgramData[channel.channelId];
  let responsetext;
  let jObj = {};

  try {
    responsetext = await feedResponse.clone().text();
    jObj = parser.parse(responsetext);
    // channelProgramData[channel.id] = jObj;
  } catch (error) {
    console.warn(`[debug] error: `, error);
  }

  return jObj;
};

const getChannelTVListing = async ({ channel }) => {
  const url = `${middlewareUrl}${channelListingEndpoint}?feedUrl=${
    channel.feedUrl
  }&startTime=${Date.now()}&startCurrentDay=${new Date().setHours(0, 0, 0, 0)}`;
  const channelListingResponse = await fetch(url);
  const channelListing = await channelListingResponse.json();

  channel.programs = channelListing.programs;

  channel.firstProgramStart = channelListing.firstProgramStart;

  return channel;
};

const getTvListings = async (userId, params) => {
  const { count = 20, offset = 0, startTime, endTime } = params;
  const cmsConfiguration = await getConfiguration(userId);
  const channelsConfigField = cmsConfiguration.integrations?.amagi?.channels;
  const channelResponse = await fetch(
    `${middlewareUrl}${listingsEndpoint}?entryId=${channelsConfigField}&startTime=${startTime}&endTime=${endTime}&offset=${offset}&count=${count}&startCurrentDay=${new Date().setHours(
      0,
      0,
      0,
      0
    )}`
  );
  return channelResponse.json();
};

const getChannelTvListings = async ({ channel }) => {
  const channelTVListings = await getChannelTVListing({ channel });
  channelTVListings.programs = channelTVListings.programs.map(program =>
    new LiveProgramBuilder({ ...program })
      .buildDates(channelTVListings.firstProgramStart)
      .buildTitle()
      .buildVideoUrl(channelTVListings)
      .buildCMSChannelId(channelTVListings)
      .buildId(channelTVListings)
      .buildImages()
      .create()
  );
  // Required for the onClick of the Shelf Item
  channel.programs = channelTVListings.programs;
  return channelTVListings.programs[0];
};

// NOTE: See IMAGE_TYPE in constants.ts
// This is not an exaustive check
const getImageType = aspectRatio => {
  switch (aspectRatio) {
    case '16:9':
      return 'backdrop';
    case '2:3':
      return 'poster';
    default:
      return 'other';
  }
};

// Make it compatible with Accedo's OVP response
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseAmagiQueryResponse_old = response => {
  const parsed =
    response?.entity_objects.map(entry => {
      const metadata = entry.expanded_asset_object || {};
      const language = metadata?.title?.[0]?.language;
      const images =
        metadata.images?.map(image => {
          const {
            expanded_image_object: {
              aspect_ratio,
              width,
              height,
              storage_url: url,
              source: id
            }
          } = image || {};
          return {
            type: getImageType(aspect_ratio),
            width,
            height,
            url,
            id
          };
        }) || [];

      const release = new Date(
        metadata?.releases?.[0]?.date_released
      ).getTime();

      const contents =
        metadata?.media_resources?.map(resource => {
          const {
            expanded_media_resource_object: {
              storage_url: url,
              container_format: format,
              video_tracks,
              duration: duration_str,
              hash_value
            }
          } = resource || {};
          const duration = parseFloat(duration_str) * 1000;
          const { frame_width: width, frame_height: height } =
            video_tracks?.[0] || {};

          return {
            url,
            format,
            width,
            height,
            language,
            duration,
            geoLock: false,
            id: hash_value
          };
        }) || [];

      return {
        id: encodeURIComponent(entry.asset_uri),
        title: metadata?.title?.[0]?.text,
        images,
        description: metadata?.long_description?.[0]?.text,
        type: metadata?.additional_type?.text?.toLowerCase() || 'movie',
        metadata: [{ name: 'language', value: language }],
        parentalRatings: metadata?.ratings.map(ratingEntry => {
          const { scheme, rating_value: rating } = ratingEntry;
          return {
            scheme,
            rating
          };
        }),
        availableDate: release,
        publishedDate: release,
        categories:
          metadata?.genre?.reduce((categories, category) => {
            const { input_text, text, language: category_language } =
              category || {};

            if (!input_text) {
              return categories;
            }

            categories.push({
              title: input_text,
              description: text,
              id: `${input_text}-${category_language}-${text}`
            });

            return categories;
          }, []) || [],
        contents,
        // NOTE: 'ovp' key is an injected value to make easier the detection
        ovp: 'amagi'
      };
    }) || [];

  return parsed;
};

const parseAmagiVODResponse = (response, type) => {
  const parsed = response?.entries?.map(entry => {
    const {
      guid,
      title,
      longDescription,
      shortDescription,
      'media:thumbnail': backdrop,
      'media:content': media,
      releaseDate,
      genre,
      writer,
      director,
      cast
    } = entry || {};

    const description = longDescription || shortDescription;

    const credits = [
      { role: 'director', name: director, picture: '' },
      { role: 'writer', name: writer, picture: '' },
      ...cast
        .split(',')
        .map(c => ({ role: 'cast', name: c.trim(), picture: '' }))
    ];

    const release = new Date(releaseDate).getTime();

    const images = [
      {
        type: 'backdrop',
        width: backdrop['@width'],
        height: backdrop['@height'],
        url: `https://res.cloudinary.com/accedotv/image/fetch/w_570,q_70,fl_progressive,c_auto/f_jpg/${backdrop['@url']}`,
        id: backdrop['@url']
      }
    ];

    const contents = [
      { duration: media['@duration'] * 1000, url: media['@url'] }
    ];

    const categories = genre.split(',').map(g => {
      const trimmed = g.trim();
      return {
        title: trimmed,
        description: trimmed,
        id: trimmed
      };
    });

    return {
      id: `amagi_vod_${guid}`,
      title,
      images,
      description,
      type,
      parentalRatings: [],
      availableDate: release,
      publishedDate: release,
      metadata: [{ name: 'language', value: 'en' }],
      categories,
      contents,
      credits,
      // NOTE: 'ovp' key is an injected value to make easier the detection
      ovp: 'amagi'
    };
  });

  return parsed;
};

const parseAmagiEventResponse = (response, type) => {
  const parsed =
    response?.entries?.map(entry => {
      const {
        id: aid,
        name: title,
        // type,
        metadata,
        start_time, // number (seconds)
        duration_seconds,
        // Schedule comes when requesting a single item
        schedule: {
          duration_seconds: duration_seconds_2,
          start_time: start_time_2 // string (ISO string)
        } = {},
        description
      } = entry || { schedule: {} };

      const release = new Date(
        start_time ? start_time * 1000 : start_time_2
      ).getTime();
      const duration = (duration_seconds || duration_seconds_2) * 1000;
      const { value: url } =
        metadata.find(meta => meta.name === 'PlaybackUrl') || {};
      const { value: genre } =
        metadata.find(meta => meta.name === 'Genre') || {};
      const { value: image } =
        metadata.find(meta => meta.name === 'Thumbnail') || {};
      const contents = [
        {
          duration,
          url
        }
      ];

      return {
        id: `amagi_evt_${aid}`,
        title,
        images: [
          {
            type: 'backdrop',
            width: 1920,
            height: 1080,
            url: image,
            id: image
          }
        ],
        description,
        type,
        parentalRatings: [],
        availableDate: release,
        publishedDate: release,
        metadata: [{ name: 'language', value: 'en' }, ...metadata],
        categories: [{ title: genre, description: genre, id: genre }],
        contents,
        credits: [],
        // NOTE: 'ovp' key is an injected value to make easier the detection
        ovp: 'amagi'
      };
    }) || [];

  return parsed;
};

/**
 * @param {{ query: string, itemsPerPage: number, genre: string, userId: string }} props Object with keys for request.
 * @returns {{ items: Array.<unknown>, total: number }} formated response.
 */
export const getItemsByQuery = async ({
  query,
  userId,
  itemsPerPage: pageSize,
  genre,
  pageNumber,
  eventId, // For replays
  assetId // For VOD
}) => {
  if (!query) {
    return null;
  }

  const optionsRequest = {
    userId,
    pageSize,
    genre,
    assetId,
    eventId,
    pageNumber
  };

  const queryParams = getQueryStringParameters(query) || {};

  const searchParams = new URLSearchParams();
  const params = { ...optionsRequest, ...queryParams };
  Object.keys(params).forEach(key => {
    if (optionsRequest[key] || queryParams[key]) {
      searchParams.append(key, params[key]);
    }
  });

  const cleanQuery = query.split('?')[0];
  // If query is a full urls, use that instead
  const baseUrl = query.startsWith('http')
    ? cleanQuery
    : `${amagiOvpUrl}${cleanQuery}`;

  const hasParams = searchParams.toString().length > 0;
  const fetchUrl = hasParams
    ? `${baseUrl}?${searchParams.toString()}`
    : baseUrl;

  try {
    const response = await fetch(fetchUrl);
    const data = await response.json();
    let items = [];

    if (query.includes('movies')) {
      items = parseAmagiVODResponse(data, 'movie');
    } else if (query.includes('events')) {
      items = parseAmagiEventResponse(
        data,
        // if query param 'when' is 'later', it means we want to fetch
        // upcoming events. For NOOP on click we need type 'other'.
        params.when === 'later' ? 'other' : 'movie'
      );
    } else {
      items = parseAmagiVODResponse(data, 'other');
    }

    // NOTE: Total should return data.totalCount once
    // the API is returning proper data. Otherwise unacurate total
    // causes a focus loss in the swimlane
    return { items, total: items.length };
  } catch (error) {
    errorHandler(error);
  }
};

export const getMovieById = async id => {
  if (!id) {
    return Promise.resolve(null);
  }

  // AssetId has 'amagi_vod_' or 'amagi_evt_' prefix
  // we need to remove it for requesting the right asset
  const props =
    // 'vod' or 'evt'
    id.substring(6, 9) === 'vod'
      ? {
          query: '/movies',
          assetId: id.substring(10)
        }
      : {
          query: '/events',
          eventId: id.substring(10)
        };

  try {
    const { items } = await getItemsByQuery({
      ...props,
      itemsPerPage: 1,
      pageNumber: 1
    });

    return items[0];
  } catch (error) {
    errorHandler(error);
  }
};

export default {
  getChannelData,
  getTvListings,
  getChannelTvListings
};
