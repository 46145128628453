/**
 * this logic is mirrored in theme/vw.scss to provide same functionality in scss
 */

import getResolution from '#/utils/getResolution';

const viewportWidth = 1920;

let scale = 0;

function getScale() {
  if (!scale) {
    const resolution = getResolution();
    if (resolution.width) {
      scale = resolution.width / viewportWidth;
    }
  }
  return scale;
}

// scaled pixel value from a 1080 based pixel value
export function scaledPixel(value) {
  return value * getScale();
}

// 1080 based pixel value from a pixel value that has been scaled
export function unScaledPixel(value) {
  return value / getScale();
}

export function vwValue(value) {
  return (value / viewportWidth) * 100;
}

export function pxValue(vwVal) {
  return ((vwVal * viewportWidth) / 100) * getScale();
}

export default function vw(value) {
  return `${(value / viewportWidth) * 100}vw`;
}
