import React from 'react';
import PropTypes from 'prop-types';
import Modal from '#/components/Modal/Modal';
import SelectorItem from '#/components/Item/SelectorItem/SelectorItem';
import ContentGrid from '#/components/ContentGrid/ContentGrid';
import { getTranslation } from '#/services/i18n';
import theme from './selectorList.scss';

const TRANSLATION_KEYS = {
  subs: 'subtitles',
  audio: 'audio'
};

const SelectorList = ({
  items,
  onClick,
  selectedItem,
  nav,
  type,
  enableClear
}) => {
  const selectorItems = [...items];
  const disableOption = {
    id: 'clear',
    label: getTranslation('list.clear')
  };

  if (!selectorItems || selectorItems.length === 0) {
    return null;
  }

  if (
    enableClear &&
    !selectorItems.find(item => item.id === disableOption.id) &&
    selectedItem
  ) {
    selectorItems.unshift(disableOption); // we add the disable option
  }

  let idMapIndex = 0;
  const idxToIdMap = {};
  selectorItems.forEach(({ id: componentId }) => {
    idxToIdMap[componentId] = idMapIndex;
    idMapIndex += 1;
  });

  return (
    <Modal>
      <div className={theme.container}>
        <div className={theme.title}>
          {getTranslation(TRANSLATION_KEYS[type] || type)}
        </div>
        <div className={theme.center}>
          <ContentGrid
            data={selectorItems}
            nav={nav}
            keyProperty="id"
            itemWidth={475}
            itemHeight={13}
            vertical
            headPadding={400}
            tailPadding={0}
            spacing={90}
            width={500}
            height={1080}
            onClick={item => onClick(item.data, type)}
            DisplayComponent={SelectorItem}
            displayComponentProps={{
              selectedItem,
              type
            }}
            initialState={{
              id: selectedItem ? selectedItem.id : items?.[0]?.id,
              position: 0
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

SelectorList.propTypes = {
  enableClear: PropTypes.bool,
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  type: PropTypes.string,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default React.memo(SelectorList);
